import fetchy from "./index";

const stotraController = {
  getAllStotra: async () => {
    const data = await fetchy("/api/v1/stotra").then(({ message, data, error }) => {
      if (message) return { data, message };
      if (error) return { error };
    });
    return data;
  },
};

export default stotraController;
