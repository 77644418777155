import React from "react";

import { Typography, Stack } from "@mui/material";

function PathHeader({ header, subPath }) {
  return (
    <Stack py={2}>
      <Typography
        className="chanakya_font"
        sx={{ color: "primary.main", fontSize: "36px", fontWeight: "bold" }}
      >
        {header}
      </Typography>
      <Typography className="chanakya_font" sx={{ fontSize: "24px", fontWeight: "bold" }}>
        {subPath}
      </Typography>
    </Stack>
  );
}

export default PathHeader;
