const initialState = [];

export default function stotra(state = initialState, { type, payload }) {
  switch (type) {
    case "POPULATE_STOTRA_DATA":
      return payload;
    default:
      return state;
  }
}
