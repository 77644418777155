import { configureStore } from "@reduxjs/toolkit";

import stotra from "reducers/stotra";
import config from "reducers/config";

const store = configureStore({
  reducer: {
    stotra,
    config,
  },
});

export default store;
