import theme from "theme";

const styles = {
  mainBox: { minHeight: "calc(100svh - 150px)", backgroundColor: theme.dimYellow, pb: 8 },
  subBox: { maxWidth: theme.maxWidth, margin: "0 auto", px: 2 },
  card: {
    height: 275,
    pb: 1,
    border: "1px solid #ccc",
    boxShadow: 3,
    cursor: "pointer",
    borderRadius: "10px",
    "&:hover": {
      boxShadow: 5,
    },
    width: "100%",
  },
};

export default styles;
