import { AccountCircle, Home } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const headerOptions = [
  { title: "परिचय", path: "/about" },
  { title: "वैदिक", _path: "/vedic" },
  { title: "लौकिक", path: "/allStotra" },
  { title: "शब्दकोश", _path: "/shabdakosha" },
  { title: "गुरुकुल", _path: "/gurukul" },
  { title: "अडियो", _path: "/audio" },
  { title: "भिडियो", _path: "/video" },
  { title: "दान", _path: "/daan" },
];

function NavBar() {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      alignItems="center"
      pl={2}
      sx={{ display: { xs: "none", xsa: "flex" } }}
    >
      <Stack direction="row" flex={1} gap={2} flexWrap="wrap" pt={1}>
        <Home
          onClick={() => navigate("/")}
          sx={{ color: "#d11501", cursor: "pointer" }}
        />

        {headerOptions.map((o) => (
          <Box key={o.title}>
            <Typography
              sx={{
                fontWeight: "bold",
                "&:hover": { color: "#d11501", cursor: "pointer" },
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                if (!o.path) return;
                navigate(o.path);
              }}
            >
              <span style={{ fontSize: "12px", paddingRight: "4px" }}>::</span> {o.title}
            </Typography>
          </Box>
        ))}
      </Stack>

      <AccountCircle sx={{ color: "white", cursor: "pointer", mt: 1 }} />
    </Stack>
  );
}

export default NavBar;
