import { fetchConfig } from "actions/config";
import { fetchStotra } from "actions/stotra";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

function Initialize() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStotra());
    dispatch(fetchConfig());
  }, []);
}

export default Initialize;
