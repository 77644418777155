import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import tiktok from "assets/tiktok.svg";
import facebook from "assets/facebook.svg";
import instagram from "assets/instagram.svg";

const footerStyles = {
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    pb: 1,
    borderBottom: "2px solid #FEBC5F",
  },
  link: {
    fontSize: "14px",
    py: 1.5,
    borderBottom: "1px solid #FEBC5F",
    "&:hover": {
      color: "primary.main",
      cursor: "pointer",
    },
  },
};

const Footer = () => {
  return (
    <>
      <Box sx={{ maxWidth: "1180px", margin: "0 auto", py: 5, px: 2 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "auto", sm: "1fr auto" },
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "auto", xsa: "1fr 1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Typography sx={footerStyles.title}>सम्पूण ग्रन्हर</Typography>
              <Typography sx={footerStyles.link}>वैक वाङ्मयसम्बद्ध </Typography>
              <Typography sx={footerStyles.link}>लौकिक साहित्यसम्बद</Typography>
              <Typography sx={footerStyles.link}>शेष मन्त्रहर</Typography>
            </Box>
            <Box>
              <Typography sx={footerStyles.title}>विषय</Typography>
              <Typography sx={footerStyles.link}>पुस्तक</Typography>
              <Typography sx={footerStyles.link}>अडियो</Typography>
              <Typography sx={footerStyles.link}>भिडियो</Typography>
            </Box>
            <Box>
              <Typography sx={footerStyles.title}>हाम्रो नी</Typography>
              <Typography sx={footerStyles.link}>ोपनीयताको नीत</Typography>
              <Typography sx={footerStyles.link}>उपयोग एवं शर्तहर</Typography>
              <Typography sx={footerStyles.link}>सामान्य प्रश्न</Typography>
            </Box>
          </Box>

          <Stack direction={{ xs: "column", xsa: "row" }} gap={3}>
            <Stack
              sx={{
                backgroundColor: "secondary.main",
                p: 1,
                borderRadius: "10px",
                minHeight: "170px",
              }}
            >
              <Box flex={1} sx={{ backgroundColor: "pink" }}></Box>
              <Typography sx={{ fontSize: "12px", color: "white", px: 2, pt: 0.5 }}>
                Scan to download the App
              </Typography>
            </Stack>
            <Box>
              <Stack
                direction="row"
                sx={{
                  borderBottom: "2px solid #FEBC5F",
                  pb: 1,
                }}
              >
                <img
                  src={facebook}
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=61556036161308",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer" }}
                />

                <img
                  src={instagram}
                  onClick={() =>
                    window.open("https://www.instagram.com/kalankashnepal", "_blank")
                  }
                  style={{ cursor: "pointer" }}
                />
                <img src={tiktok} style={{ cursor: "pointer" }} />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Box py={2} sx={{ backgroundColor: "#FEE3A3" }}>
        <Typography sx={{ textAlign: "center", fontSize: "14px" }}>
          © Copyright |{" "}
          <span style={{ color: "#FE8821" }}>Vaidik Anusandhan Guthi (VAG)</span> | All
          Rights Reserved 2024
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
