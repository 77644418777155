const topStotras = [
  {
    title: "वदिक वाङ्मयसम्बद्ध सूक्तहर",
    stotra: `वैक वाङ्मय सम्बद्ध भिन्न सVक्ति एवं आदर्श 
    वाक्यहरू जसले मार्ग निEशन गर्छन् 6`,
  },
  {
    title: "लकिक साहित्यका स्तोत्रहरू",
    stotra: `संस्कृत वाङ्मय सम्बद्ध ©भिन्न सVक्ति एवं आदर्श 
    वाक्यहरू जसले मार्ग निशन गर्छन् 6`,
  },
  {
    title: "वदिक विशेष मन्त्रहर",
    stotra: `वैक वाङ्मय सम्बद्ध भिन्न सक्ति एवं आदर्श 
    वाक्यहरू जसले मार्ग निEशन गर्छन् 6`,
  },
];

const lines = [
  {
    text: "वसुदेवसुतं देवं कंसचाणूरमर्दनम्।",
    start: "0",
    end: "29.06",
  },
  {
    text: "देवकीपरमानन्दं कृष्णं वन्दे जगद्गुरुम्॥",
    start: "29.06",
    end: "52",
  },
  {
    text: "अजोऽपि सन्नव्ययात्मा भूतानामीश्वरोऽपि सन्।",
    start: "52",
    end: "74.69",
  },
  {
    text: "प्रकृतिं स्वामधिष्ठाय सम्भवाम्यात्ममायया॥",
    start: "74.69",
    end: "94.31",
  },
  {
    text: "यदा यदा हि धर्मस्य ग्लानिर्भवति भारत।",
    start: "94.31",
    end: "114.72",
  },
  {
    text: "अभ्युत्थानमधर्मस्य तदात्मानं सृजाम्यहम्॥",
    start: "114.72",
    end: "134",
  },
  {
    text: "परित्राणाय साधूनां विनाशाय च दुष्कृताम्।",
    start: "134",
    end: "153.06",
  },
  {
    text: "धर्मसंस्थापनार्थाय सम्भवामि युगे युगे॥",
    start: "153.06",
    end: "171.84",
  },
  {
    text: "जन्म कर्म च मे दिव्यमेवं यो वेत्ति तत्त्वत:।",
    start: "171.84",
    end: "190.98",
  },
  {
    text: "त्यक्त्वा देहं पुनर्जन्म नैति मामेति सोऽर्जुन॥",
    start: "190.98",
    end: "208.98",
  },
  {
    text: "वीतरागभयक्रोधा मन्मया मामुपाश्रिता:।",
    start: "208.98",
    end: "227.51",
  },
  {
    text: "बहवो ज्ञानतपसा पूता मद्भावमागता:॥",
    start: "227.51",
    end: "245.46",
  },
  {
    text: "वसुदेवसुतं देवं कंसचाणूरमर्दनम्।",
    start: "245.46",
    end: "263.47",
  },
  {
    text: "देवकीपरमानन्दं कृष्णं वन्दे जगद्गुरुम्॥",
    start: "263.47",
    end: "280.88",
  },
  {
    text: "कृष्णं वन्दे जगद्गुरुम्",
    start: "280.88",
    end: "289.86",
  },
  {
    text: "हरे राम हरे राम राम राम हरे हरे",
    start: "289.86",
    end: "306.91",
  },
  {
    text: "हरे कृष्ण हरे कृष्ण कृष्ण कृष्ण हरे हरे",
    start: "306.91",
    end: "Infinity",
  },
];

const meaning = [
  {
    title: `कंस और चाणूरका वध करने वाले, देवकीके आनन्दवर्धन, वसुदेवनन्दन जगद्गुरु
    श्रीकृष्णचन्द्रकी मैं वन्दना करता हूँ।`,
    start: "0",
    end: "52.00",
  },
  {
    title: `मैं अजन्मा और अविनाशी स्वरूप होते हुए भी तथा समस्त प्राणियोंका ईश्वर होते हुए
    भी अपनी प्रकृतिको अधीन करके अपनी योगमायासे प्रकट होता हूँ।`,
    start: "52.00",
    end: "94.31",
  },
  {
    title: `हे भारत! जब-जब धर्मकी हानि और अधर्मकी वृद्धि होती है, तब-तब ही मैं अपने रूपको
    रचता हूँ अर्थात् साकाररूपसे लोगोंके सम्मुख प्रकट होता हूँ।`,
    start: "94.31",
    end: "133.51",
  },
  {
    title: `साधु पुरुषोंका उद्धार करनेके लिये, पाप-कर्म करनेवालोंका विनाश करनेके लिये और
    धर्मकी अच्छी तरहसे स्थापना करनेके लिये मैं युग-युगमें प्रकट हुआ करता हूँ।`,
    start: "133.51",
    end: "171.84",
  },
  {
    title: `हे अर्जुन! मेरे जन्म और कर्म दिव्य अर्थात् निर्मल और अलौकिक हैं—इस प्रकार जो
    मनुष्य तत्त्वसे जान लेता है, वह शरीरको त्यागकर फिर जन्मको प्राप्त नहीं होता,
    किंतु मुझे ही प्राप्त होता है।`,
    start: "171.84",
    end: "208.98",
  },
  {
    title: `पहले भी, जिनके राग, भय और क्रोध सर्वथा नष्ट हो गये थे और जो मुझमें
    अनन्यप्रेमपूर्वक स्थित रहते थे, ऐसे मेरे आश्रित रहनेवाले बहुत-से भक्त
    उपर्युक्त ज्ञानरूप तपसे पवित्र होकर मेरे स्वरूपको प्राप्त हो चुके हैं।`,
    start: "208.98",
    end: "245.46",
  },
  {
    title: `कंस और चाणूरका वध करने वाले, देवकीके आनन्दवर्धन, वसुदेवनन्दन जगद्गुरु
    श्रीकृष्णचन्द्रकी मैं वन्दना करता हूँ।`,
    start: "245.46",
    end: "289.86",
  },
  {
    title: `हरे राम हरे राम राम राम हरे हरे`,
    start: "289.86",
    end: "306.91",
  },
  {
    title: `हरे कृष्ण हरे कृष्ण कृष्ण कृष्ण हरे हरे`,
    start: "306.91",
    end: "Infinity",
  },
];

export { topStotras, lines, meaning };
