import { useContext } from "react";

import { StoreContext } from "./index";

const useContextMenu = (items) => {
  //eslint-disable-next-line
  const [state, setState] = useContext(StoreContext);

  const openContextMenu = (e, data) => {
    e.preventDefault();
    e.persist();
    setState({ visible: true, items, ref: e, data });
  };
  return openContextMenu;
};

export default useContextMenu;
