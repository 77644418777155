import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import headerbg from "assets/header-image.png";
import logo from "assets/logo.png";
import HeaderSearch from "./HeaderSearch";
import NavBar from "./NavBar";
import NavBarDrawer from "./NavBarDrawer";

function Header() {
  return (
    <Box
      sx={{
        background: `url(${headerbg}) no-repeat top`,
        backgroundSize: "cover",
        height: "150px",
      }}
    >
      <Box sx={{ width: "100vw", maxWidth: "1180px", margin: "0 auto", px: 2 }}>
        <Stack direction={{ xs: "column", xsa: "row" }} sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", top: 0, left: 0, right: 0 }}>
            <Typography
              sx={{ color: "white", fontSize: "14px", textAlign: "center", pt: 1 }}
            >
              ॥ ॐ नम: शिवाय॥
            </Typography>{" "}
          </Box>
          <Box
            sx={{
              "& > img": {
                height: { xs: "50px", xsa: "100px" },
              },
            }}
          >
            <img src={logo} />
          </Box>

          <Box
            flex={1}
            pt={{ xs: 0, xsa: 2 }}
            sx={{
              display: "grid",
              gridTemplateRows: { xs: "auto", xsa: "max-content auto" },
            }}
          >
            <HeaderSearch />

            <NavBar />
          </Box>
          <NavBarDrawer />
        </Stack>
      </Box>
    </Box>
  );
}

export default Header;
