import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { allStotraStyles } from "./styles";
import {
  Book,
  LibraryBooks,
  LibraryMusic,
  MenuBook,
  PersonalVideo,
} from "@mui/icons-material";

function AllStotraBox() {
  return (
    <Box sx={allStotraStyles.box}>
      <Typography sx={allStotraStyles.header}>सम्पूर्ण स्तोत्रहर</Typography>

      <Stack direction={{ xs: "column", mda: "row" }}>
        <ItemUI title="वदिक वाङ्मयसम्बद्ध " />
        <ItemUI title="लकिक साहित्यसम्बद्ध" />
        <ItemUI title="विशेष मन्त्रहर" />
      </Stack>

      <Box sx={{ border: "2px solid #d11501", mt: 4 }} />
    </Box>
  );
}

const ItemUI = ({ title }) => {
  return (
    <Box flex={1} sx={allStotraStyles.itemBox}>
      <Typography sx={allStotraStyles.title}>{title}</Typography>

      <Stack direction="row" gap={2}>
        <Stack flex={1} alignItems="center" sx={allStotraStyles.one}>
          <MenuBook sx={allStotraStyles.icon1} fontSize="large" />
          <Typography sx={allStotraStyles.text}>पुस्तक</Typography>
        </Stack>

        <Stack flex={1} alignItems="center" sx={allStotraStyles.two}>
          <LibraryMusic sx={{ color: "white" }} fontSize="large" />
          <Typography sx={allStotraStyles.text}>अडियो</Typography>
        </Stack>
      </Stack>

      <Stack direction="row" gap={2} pt={2}>
        <Stack flex={1} alignItems="center" sx={allStotraStyles.two}>
          <PersonalVideo sx={{ color: "white" }} fontSize="large" />
          <Typography sx={allStotraStyles.text}>भिडियो</Typography>
        </Stack>

        <Stack flex={1} alignItems="center" sx={allStotraStyles.one}>
          <LibraryBooks sx={allStotraStyles.icon1} fontSize="large" />
          <Typography sx={allStotraStyles.text}>विवरण</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AllStotraBox;
