import React, { createRef, useEffect, useRef, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { audioBoxStyles } from "./styles";
import { lines, meaning } from "./setup";
import { PlayCircle, VolumeUp } from "@mui/icons-material";
import music from "assets/YadaYadaHomePage.mp3";
import logo from "assets/logo.png";

function AudioBox() {
  const lineRefs = Array(lines.length)
    .fill()
    .map((_) => createRef());

  const [activeIndex, setActiveIndex] = useState(0);
  const [playing, setPlaying] = useState(null);
  const [time, setTime] = useState(0);
  const audioRef = useRef();
  const boxRef = useRef();

  const handleClick = () => {
    setPlaying((prev) => {
      prev ? audioRef.current.pause() : audioRef.current.play();
      return !prev;
    });
  };

  const scrollToMiddle = (i) => {
    if (!lineRefs?.[i]?.current?.offsetTop) return;

    boxRef.current.scrollTo({
      behavior: "smooth",
      top: lineRefs[i].current.offsetTop - 105,
    });
  };

  useEffect(() => {
    scrollToMiddle(activeIndex);
  }, [activeIndex]);

  const handleClickLine = (l, i) => {
    audioRef.current.currentTime = l.start;
  };

  const handleUpdateTime = () => {
    const time = audioRef.current.currentTime;
    setTime(time);
    const activeLineIndex = lines.findIndex((l) => +time >= +l.start && +time < +l.end);
    activeLineIndex !== activeIndex && setActiveIndex(activeLineIndex);
  };

  const handleEnded = () => {
    setActiveIndex(0);
    setPlaying(false);
    setTime(0);
  };

  return (
    <>
      <audio
        ref={audioRef}
        controls={false}
        onTimeUpdate={handleUpdateTime}
        onEnded={handleEnded}
      >
        <source src={music} type="audio/mpeg" />
      </audio>

      <Stack direction={{ xs: "column", xsa: "row" }} sx={audioBoxStyles.box}>
        <Stack flex={1} sx={audioBoxStyles.slokBox}>
          <Box
            ref={boxRef}
            className="fancy_scroll"
            sx={{
              overflow: "auto",
              background: `url(${logo}) no-repeat center`,
              backgroundSize: "contain",
            }}
          >
            {lines.map((l, i) => (
              <Typography
                ref={lineRefs[i]}
                key={i}
                onClick={() => handleClickLine(l, i)}
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  py: 0.5,
                  color: activeIndex === i ? "#fd7e35" : "black",
                  cursor: "pointer",
                  scrollSnapAlign: "center",
                }}
              >
                {l.text}
              </Typography>
            ))}
          </Box>
          <IconButton
            sx={{ position: "absolute", bottom: 0, right: 10 }}
            onClick={handleClick}
          >
            {playing ? (
              <VolumeUp sx={{ color: "white" }} fontSize="medium" />
            ) : (
              <PlayCircle sx={{ color: "white" }} fontSize="medium" />
            )}
          </IconButton>
        </Stack>
        <Stack flex={1} sx={audioBoxStyles.meaningBox}>
          <Box sx={{ overflow: "auto", pr: 4 }}>
            {meaning.map((m, i) => (
              <Typography
                key={i}
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  py: 1,
                  color: "white",
                  display: +time >= +m.start && +time < +m.end ? "block" : "none",
                  lineHeight: "2em",
                }}
              >
                {m.title}
              </Typography>
            ))}
          </Box>
        </Stack>
      </Stack>
    </>
  );
}

export default AudioBox;
