import React from "react";

import { Box, Typography, Stack } from "@mui/material";
import theme from "theme";
import { stotraSuchiFilterOptions } from "./setup";
import CollapsibleDiv from "components/UI/CollapsibleDiv";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

function SideNav() {
  const config = useSelector((state) => state.config);

  const [query, setQuery] = useSearchParams();

  const category = query.get("category") || "सबै";

  const handleClickFilter = (type, value) => {
    setQuery(
      (prev) => {
        prev.set(type, value);

        return prev;
      },
      { replace: true }
    );
  };

  return (
    <Box
      className="box-shadow-3"
      sx={{
        width: { xs: "100%", sm: "300px" },
        backgroundColor: theme.dimmerYellow,
        p: 2,
      }}
    >
      <Typography
        pt={0.5}
        pb={2}
        sx={{
          color: "primary.main",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        फिल्टर गर्नुहोस्
      </Typography>

      <Stack gap={2}>
        <CollapsibleDiv
          defaultExpanded={true}
          value={category}
          title="स्तोत्र सूचि"
          options={[
            {
              title: "सबै",
              value: "सबै",
            },
            ...(config?.stotra_category || []).map((s) => ({
              title: s,
              value: s,
            })),
          ]}
          handleClickFilter={handleClickFilter}
        />
        <CollapsibleDiv title="विधा" options={[]} handleClickFilter={handleClickFilter} />
        <CollapsibleDiv title="भाषा" options={[]} handleClickFilter={handleClickFilter} />
      </Stack>
    </Box>
  );
}

export default SideNav;
