import book1 from "assets/book1.jpg";
import book2 from "assets/book2.jpg";
import book3 from "assets/book3.jpg";
import book4 from "assets/book4.jpg";
import book5 from "assets/book5.jpg";
import book6 from "assets/book6.jpg";
import book7 from "assets/book7.jpg";
import book8 from "assets/book8.jpg";

const stotraSuchiFilterOptions = [
  {
    title: "सबै",
    value: "सबै",
  },
  {
    title: "शिवस्तोत्र",
    value: "शिवस्तोत्र",
  },
  {
    title: "सहस्त्रनामस्तोत्र",
    value: "सहस्त्रनामस्तोत्र",
  },
  {
    title: "देवीस्तोत्र",
    value: "देवीस्तोत्र",
  },
  {
    title: "गणेश",
    value: "गणेश",
  },
  {
    title: "हनुमत्स्तोत्र",
    value: "हनुमत्स्तोत्र",
  },
  {
    title: "कृष्ण",
    value: "कृष्ण",
  },
  {
    title: "बिष्णु",
    value: "बिष्णु",
  },
  {
    title: "सुर्य",
    value: "सुर्य",
  },
  {
    title: "राम",
    value: "राम",
  },
  {
    title: "विविध",
    value: "विविध",
  },
  {
    title: "वेदान्त",
    value: "वेदान्त",
  },
];

const sortOptions = [
  {
    title: "नवीनतम",
    value: "नवीनतम",
  },
  {
    title: "सामान्य",
    value: "सामान्य",
  },
  {
    title: "शीर्षक",
    value: "शीर्षक",
  },
  {
    title: "लोकप्रिय",
    value: "लोकप्रिय",
  },
];

const stotraList = [
  {
    title: "विष्णुसहस्रनाम",
    image: book1,
  },
  {
    title: "राम",
    image: book2,
  },
  {
    title: "विवेक",
    image: book3,
  },
  {
    title: "विकास",
    image: book4,
  },
  {
    title: "कर्ण",
    image: book5,
  },
  {
    title: "महाभारत (स्त्रीपर्व)",
    image: book6,
  },
  {
    title: `महाभारत (सौप्तिकपर्व)`,
    image: book7,
  },
  {
    title: "महाभारत (शल्यपर्व)",
    image: book8,
  },
  {
    title: "महाभारत (कर्णपर्व)",
    image: book1,
  },
  {
    title: "मिनीकृत महाभारत मभक्तों की गाथ",
    image: book2,
  },
  {
    title: "महाभारत (द्रोणपर्व)",
    image: book3,
  },
  {
    title: "क्षिप्त शिवपुराण (ओड़िया)",
    image: book4,
  },
  {
    title: "राम",
    image: book2,
  },
  {
    title: "विवेक",
    image: book3,
  },
  {
    title: "विष्णुसहस्रनाम",
    image: book1,
  },
  {
    title: "विकास",
    image: book4,
  },
  {
    title: "कर्ण",
    image: book5,
  },
  {
    title: "महाभारत (स्त्रीपर्व)",
    image: book6,
  },
  {
    title: `महाभारत (सौप्तिकपर्व)`,
    image: book7,
  },
  {
    title: "महाभारत (शल्यपर्व)",
    image: book8,
  },
  {
    title: "महाभारत (कर्णपर्व)",
    image: book1,
  },
  {
    title: "मिनीकृत महाभारत मभक्तों की गाथ",
    image: book2,
  },
  {
    title: "महाभारत (द्रोणपर्व)",
    image: book3,
  },
  {
    title: "क्षिप्त शिवपुराण (ओड़िया)",
    image: book4,
  },
];

export { stotraSuchiFilterOptions, sortOptions, stotraList };
