import msgboximg from "assets/message-box-bg.png";

const commonHeaderStyles = {
  header: {
    color: "#d11501",
    fontSize: "24px",
    textAlign: "center",
    m: "20px 0 35px",
    fontWeight: 700,
  },
  sliderBox: {
    maxWidth: "1180px",
    margin: "0 auto",
    backgroundColor: "#FFF0D0",
    px: { xs: 0, xsa: 4 },
    py: 4,
    "& >.slick-slider > .slick-list": {
      margin: "0 24px",
    },
  },
};

const homeStyles = {
  main: {
    display: "grid",
    gridTemplateColumns: { xs: "auto", md: "auto 320px" },
    width: "100vw",
    maxWidth: 1180,
    margin: "0 auto",
    gap: 2,
    pb: 5,
  },
  sliderBox: {
    width: "100%",
    overflow: "hidden",
  },
  aboutContactBoxMain: {
    width: "100vw",
    backgroundColor: "#FFF0D0",
    py: 6,
  },
  aboutContactBox: {
    display: "grid",
    gridTemplateColumns: { xs: "auto", sm: "480px auto" },
    gridTemplateRows: { xs: "max-content auto", sm: "auto" },
    maxWidth: 1180,
    backgroundColor: "#FFF0D0",
    margin: "0 auto",
    gap: { xs: 0, sm: 1, md: 5 },
    px: 2,
  },
};

const sliderStyles = {
  img: {
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    padding: "8px",
    cursor: "pointer",
    position: "relative",
    zIndex: -1,
  },
  box: {
    "&>span": {
      width: "100%",
    },
    cursor: "pointer",
  },
};

const audioBoxStyles = {
  box: {
    height: { xs: "480px", xsa: "240px" },
    mt: 4,
    backgroundColor: "#ff9509",
    borderRadius: "6px",
    mx: { xs: 2, md: 0 },
  },
  slokBox: {
    backgroundColor: "#ffc72f",
    borderRadius: "6px",
    borderBottom: { xs: 0, xsa: "5px solid #f4b919" },
    borderTop: { xsa: 0, xs: "5px solid #f4b919" },
    p: 4,
    pr: 0,
    pb: 3,
    position: "relative",
    height: { xs: "240px", xsa: "auto" },
  },
  meaningBox: {
    borderBottom: "5px solid #ec8600",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
    borderBottomLeftRadius: { xs: "6px", xsa: 0 },
    p: 4,
    pr: 0,
    pb: 3,
    overflow: "auto",
    height: { xs: "240px", xsa: "auto" },
  },
};

const topStotraStyles = {
  box: {
    background: `url(${msgboximg}) no-repeat top`,
    height: "160px",
    pt: 11.25,
    display: { xs: "none", md: "block" },
  },
  header: { ...commonHeaderStyles.header },

  itemBox: { backgroundColor: "#ffe9b9", px: 5, borderRadius: { xs: "10px", md: 0 } },
  title: {
    color: "#d83c28",
    fontWeight: 700,
    fontSize: "20px",
  },
  slok: { color: "#3f220d", fontSize: "14px", fontWeight: 400 },
  nextText: {
    textAlign: "right",
    color: "#9c4439",
    fontSize: "12px",
    borderBottom: "1px solid #e4d1a9",
    pb: 2,
    mb: 2,
    fontWeight: 400,
    cursor: "pointer",
  },
};

const allStotraStyles = {
  box: {
    width: "100vw",
    maxWidth: "1180px",
    margin: "0 auto",
    backgroundColor: "#FFF0D0",
    px: 4,
    pt: 4,
    mb: 5,
  },
  header: { ...commonHeaderStyles.header },

  itemBox: {
    borderRight: { xs: 0, mda: "2px solid #d11501" },
    p: 2,
    px: 6,
    "&:nth-of-type(3)": {
      border: "0 !important",
    },
  },
  title: { textAlign: "center", pb: 4, fontWeight: "bold", fontSize: "1.25rem" },
  one: {
    backgroundColor: "white",
    py: 2,
    px: 4,
    borderRadius: "10px",
    boxShadow: 3,
    gap: 2,
  },
  icon1: { color: "#ffc72f" },
  text: { fontSize: "12px", fontWeight: "bold" },
  two: {
    backgroundColor: "#ffc72f",
    py: 2,
    px: 4,
    borderRadius: "10px",
    boxShadow: 3,
    gap: 2,
  },
};

const booksStyles = {
  box: { ...commonHeaderStyles.sliderBox },
  header: { ...commonHeaderStyles.header },
};

const topAudioboxStyles = {
  box: { ...commonHeaderStyles.sliderBox, backgroundColor: "#FFF6E1" },
  header: { ...commonHeaderStyles.header },
};

const topVideoBoxStyles = {
  box: { ...commonHeaderStyles.sliderBox },
  header: { ...commonHeaderStyles.header },
};

const granthaBibaranStyles = {
  box: { ...commonHeaderStyles.sliderBox, backgroundColor: "#FFF6E1" },
  header: { ...commonHeaderStyles.header },
};

const aboutStyles = {
  header: { ...commonHeaderStyles.header, textAlign: "left" },
  title: { ...commonHeaderStyles.header, fontSize: "16px", pb: 1, mb: 0 },
  nextText: {
    textAlign: "right",
    color: "#9c4439",
    fontSize: "12px",
    fontWeight: 400,
    cursor: "pointer",
  },
};

const contactStyles = {
  header: { ...commonHeaderStyles.header, textAlign: "left" },
};

export {
  homeStyles,
  sliderStyles,
  audioBoxStyles,
  topStotraStyles,
  allStotraStyles,
  booksStyles,
  topAudioboxStyles,
  topVideoBoxStyles,
  granthaBibaranStyles,
  aboutStyles,
  contactStyles,
};
