import { KeyboardArrowDown, Search } from "@mui/icons-material";
import { Input, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";

const options = [
  "सभी",
  "ई – पुस्तकें",
  "गीत-गोविंद",
  "कल्याण",
  "कल्पतरु",
  "ऑडियो",
  "प्रवचन",
  "अनमोल लेख",
];

function HeaderSearch() {
  const [value, setValue] = React.useState("सभी");

  return (
    <Stack direction="row" justifyContent="flex-end" pt={{ xsa: 1.5, md: 0 }}>
      <SearchDropDown />

      <Input
        size="small"
        endAdornment={<Search sx={{ color: "white" }} />}
        disableUnderline={true}
        sx={{
          background: "#ffa843",
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          border: "1px solid #ffa843",
          px: 1,
          width: { xs: "100%", xsa: "auto" },
        }}
      />
    </Stack>
  );
}

const SearchDropDown = () => {
  const [value, setValue] = React.useState("सभी");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (v) => {
    setValue(v);
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        px={0.5}
        sx={{
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
          backgroundColor: "#ffb968",
          cursor: "pointer",
        }}
        id="search-dropdown"
        aria-controls={open ? "search-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography
          sx={{
            color: "#ea7a05",
            fontSize: "14px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {value}
        </Typography>
        <KeyboardArrowDown fontSize="small" sx={{ color: "#ea7a05" }} />
      </Stack>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { py: 0 },
        }}
        sx={{ mt: 0.5 }}
      >
        {options.map((o) => (
          <MenuItem
            key={o}
            onClick={() => handleSelect(o)}
            sx={{
              color: "#ea7a05",
              backgroundColor: "#ffb968",
              p: 0.5,
              minHeight: 0,
              fontSize: "14px",
            }}
          >
            {o}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default HeaderSearch;
