import { Box } from "@mui/material";
import RangaTable from "components/RangaTable";
import React from "react";
import { tableHeader } from "./setup";

const StotraList = ({ stotraData, handleClickSlok }) => {
  const handleClick = ({ id }) => {
    handleClickSlok(id);
  };

  return (
    <Box pt={2}>
      <RangaTable
        data={stotraData}
        columns={tableHeader}
        handleClick={handleClick}
        options={{
          filterAbility: true,
          snDisplay: true,
          sortAbility: false,
          statusToggle: true,
        }}
      />
    </Box>
  );
};

export default StotraList;
