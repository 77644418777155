import fetchy from "./index";

const configController = {
  getConfig: async () => {
    const data = await fetchy("/api/v1/config").then(({ message, data, error }) => {
      if (message) return { data, message };
      if (error) return { error };
    });
    return data;
  },
};

export default configController;
