import React, { useRef, useState } from "react";

import Slider from "react-slick";

import slider1 from "assets/slider1.png";
import slider2 from "assets/slider2.png";
import slider3 from "assets/slider3.jpg";
import { Stack } from "@mui/material";

import "./index.css";
import { sliderStyles } from "./styles";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  autoplaySpeed: 6000,
  lazyLoad: true,
  autoplay: true,
  swipeToSlide: false,
  draggable: false,
};

const banners = [slider2, slider1, slider3];

function HomeSlider() {
  const handleClick = (e) => {
    console.log("clicked");
  };

  return (
    <>
      <Slider {...settings} autoplaySpeed={5000}>
        {banners.map((data, i) => (
          <Stack
            key={i}
            id={data}
            sx={sliderStyles.box}
            onClick={handleClick}
            draggable="true"
          >
            <img alt="banner" src={data} height={300} style={sliderStyles.img} />
          </Stack>
        ))}
      </Slider>
    </>
  );
}

export default HomeSlider;
