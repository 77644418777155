import React from "react";

import { Box, Typography, Stack, IconButton } from "@mui/material";

import Footer from "components/Footer";
import Header from "components/Header";
import PathHeader from "components/UI/PathHeader";
import CollapsibleDiv from "components/UI/CollapsibleDiv";

import styles from "./styles";
import theme from "theme";
import { PlayArrow } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const StotraDetails = () => {
  const { stotraID } = useParams();

  const stotraDatas = useSelector((state) => state.stotra);

  const stotraData = stotraDatas.find((s) => s.id === +stotraID);

  if (!stotraData)
    return (
      <>
        <Header />
        <Box sx={styles.mainBox}></Box>
        <Box sx={styles.subBox}></Box>
        <Footer />
      </>
    );

  return <StotraDetailsUI stotraData={stotraData} />;
};

function StotraDetailsUI({ stotraData }) {
  return (
    <>
      <Header />

      <Box sx={styles.mainBox}>
        <Box sx={styles.subBox}>
          <PathHeader
            header="स्तोत्रहरू"
            subPath={`गृह / लौकिक / स्तोत्रहरू / ${stotraData.name}`}
          />

          <Box className="box-shadow-3" p={2}>
            <Stack gap={2} className="box-shadow-3" p={2}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box>
                  {stotraData.slok.split("\n").map((s) => (
                    <React.Fragment key={s}>
                      <Typography sx={{ fontWeight: "bold", color: "primary.main" }}>
                        {s}
                      </Typography>
                    </React.Fragment>
                  ))}
                </Box>

                <Stack direction="row" gap={2}>
                  <IconButton size="small">
                    <PlayArrow color="secondary" />
                  </IconButton>
                </Stack>
              </Stack>

              <CollapsibleDiv
                defaultExpanded={true}
                title="पदच्छेद"
                description={stotraData.padched}
              />

              <CollapsibleDiv
                defaultExpanded={true}
                title="अन्वय"
                description={stotraData.anbaya}
              />

              <CollapsibleDiv
                defaultExpanded={true}
                title="पदार्थ"
                options={stotraData.padartha.split("\n").map((a) => {
                  const splitted = a.split(":-");
                  return {
                    title: (splitted?.[0] || "").trim(),
                    description: (splitted?.[1] || "").trim(),
                  };
                })}
                isTitleColored={true}
              />

              <CollapsibleDiv
                defaultExpanded={true}
                title="भावार्थ"
                description={stotraData.bhabartha}
              />

              <CollapsibleDiv
                defaultExpanded={true}
                title="शाङ्करभाष्य"
                description={stotraData?.sankarbhasya || ""}
              />
              <CollapsibleDiv
                defaultExpanded={true}
                title="टिप्पणी"
                description={stotraData.tippani}
              />
              <CollapsibleDiv
                defaultExpanded={true}
                title="छन्द"
                description={stotraData.chanda}
              />
            </Stack>

            <Typography
              pt={6}
              sx={{ color: "#D101BC", fontWeight: "bold", textAlign: "center" }}
            >
              {stotraData.puspika_wakya}
            </Typography>
            <Typography
              pb={2}
              sx={{ color: "#D101BC", fontWeight: "bold", textAlign: "center" }}
            >
              ***
            </Typography>

            <Stack
              alignItems="center"
              justifyContent="center"
              p={2}
              sx={{ backgroundColor: theme.dimYellow }}
            >
              <Typography sx={{ color: "#FF731F", fontWeight: "bold" }} pb={1}>
                धन्यवाद
              </Typography>
              <Typography>
                <strong>{stotraData.credit}</strong>, काठमाडौँ, नेपाल
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default StotraDetails;
