import { configController } from "fetchy";

const fetchConfig = () => {
  return async (dispatch) => {
    configController.getConfig().then(({ data, error }) => {
      if (data) {
        dispatch({
          type: "POPULATE_CONFIG_DATA",
          payload: data,
        });
      }
    });
  };
};

export { fetchConfig };
