import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  borderRadius: 10,
  maxWidth: "1180px",

  primary: "#FE4641",
  secondary: "#FEBC5F",
  dimYellow: "#FFF6E1",
  dimmerYellow: "#FFFAF0",

  breakpoints: {
    values: {
      xs: 0,
      xsa: 500,
      sm: 720,
      md: 900,
      mda: 1000,
      lg: 1200,
      xl: 1536,
    },
  },

  palette: {
    mode: "light",
    common: {
      black: "#1b1b1b",
      white: "",
    },
    primary: {
      main: "#FE4641",
      light: "#FE4641",
      dark: "#FE4641",
    },
    secondary: {
      main: "#FEBC5F",
      light: "#FEBC5F",
      dark: "#FEBC5F",
    },
    tertiary: {
      main: "#64748B",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    info: {
      main: "#64748B",
      light: "#ddd",
      dark: "#cccA",
    },
    error: {
      main: "#C81500",
    },
    dim: "#555", // light grey
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: 10,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        minHeight: "32px",
        "&$selected": {
          background: "#004022",
          color: "white !important",
          margin: "0.5rem",
          borderRadius: 10,
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiDivider: {
      root: {
        width: "350px",
        margin: "5px 0px",
      },
    },
  },

  //Flex
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default theme;
