import React, { useState } from "react";

import { Box, Stack, Typography, Button } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Footer from "components/Footer";
import Header from "components/Header";
import PathHeader from "components/UI/PathHeader";

import styles from "./styles";
import theme from "theme";

import book8 from "assets/book8.jpg";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import RelatedStotra from "./RelatedStotra";
import { useSelector } from "react-redux";
import StotraList from "./StotraList";

const IndividualStotra = () => {
  const [query] = useSearchParams();
  const category = query.get("category");
  const book = query.get("book");

  const stotraDatas = useSelector((state) => state.stotra);
  const config = useSelector((state) => state.config);

  const relatedBook = (config?.books || []).find((b) => b.name === book);
  const stotraData = stotraDatas
    .filter((s) => {
      const case1 = category === "सबै" || !category ? true : s.category === category;
      const case2 = !book ? true : s.book_name === book;
      return case1 && case2;
    })
    .sort((a, b) => (b?.priority || 0) - (a?.priority || 0));

  const relatedStotraList = [
    ...new Set(stotraData.filter((d) => d.book_name !== book).map((s) => s.book_name)),
  ];

  if (!Object.keys(stotraDatas).length)
    return (
      <>
        <Header />
        <Box sx={styles.mainBox}></Box>
        <Box sx={styles.subBox}></Box>
        <Footer />
      </>
    );

  return (
    <IndividualStotraUI
      stotraData={stotraData}
      relatedStotraList={relatedStotraList}
      relatedBook={relatedBook}
    />
  );
};

function IndividualStotraUI({ stotraData, relatedStotraList, relatedBook }) {
  const navigate = useNavigate();

  const [value, setValue] = useState({ liked: false });

  const description = (relatedBook?.description || "")
    .split("\n")
    .map((a) => a.trim())
    .filter((a) => a);

  const handleClickRelatedBooks = (data) => {
    // navigate(`/stotra?category=${category}&book=${data.name}`);
  };

  const handleClickSlok = (id) => {
    navigate(`/stotra-details/${id}`);
  };

  return (
    <>
      <Header />

      <Box sx={styles.mainBox}>
        <Box sx={styles.subBox}>
          <PathHeader
            header="स्तोत्रहरू"
            subPath={`गृह / लौकिक / स्तोत्रहरू / ${relatedBook?.name}`}
          />

          <Box
            className="box-shadow-3"
            sx={{
              backgroundColor: theme.dimmerYellow,
              p: 4,
            }}
          >
            <Stack direction={{ xs: "column", xsa: "row" }} gap={4}>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ p: 2, backgroundColor: "white" }}
              >
                <img
                  src={book8}
                  style={{
                    height: "350px",
                    objectFit: "contain",
                  }}
                />
              </Stack>
              <Box flex={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ py: 2, borderBottom: "2px solid", borderColor: "secondary.main" }}
                >
                  <Typography>{relatedBook?.name}</Typography>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "40px",
                      height: "40px",
                      border: "1px solid",
                      borderColor: "primary.main",
                      borderRadius: "100%",
                      cursor: "pointer",
                      backgroundColor: theme.dimYellow,
                    }}
                    onClick={() => setValue((prev) => ({ ...prev, liked: !prev.liked }))}
                  >
                    {value.liked ? <Favorite color="primary" /> : <FavoriteBorder />}
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    py: 2,
                    borderBottom: "2px solid",
                    borderColor: "secondary.main",
                  }}
                >
                  <Typography sx={{ width: "150px" }}>भाषा</Typography>
                  <Typography sx={{ color: "secondary.main" }}>
                    {relatedBook?.bhasa}
                  </Typography>
                </Stack>
                {/* <Button
                  variant="contained"
                  sx={{ width: "auto", my: 2 }}
                  onClick={() => navigate(`/stotra-details/${stotraData.id}`)}
                >
                  पढ्नुहोस्
                </Button> */}
              </Box>
            </Stack>

            <Typography
              sx={{
                fontWeight: "bold",
                color: "secondary.main",
                fontSize: "22px",
                pb: 2,
              }}
            >
              स्तोत्रविवरण
            </Typography>

            <Box
              sx={{
                pb: 2,
                borderBottom: "2px solid",
                borderColor: "secondary.main",
              }}
            >
              {description.map((d, i, a) => (
                <React.Fragment key={i}>
                  <Typography
                    className="chanakya_font"
                    sx={{
                      color: "#472D1E",
                      fontSize: "22px",
                      lineHeight: "1",
                    }}
                  >
                    {d}
                  </Typography>
                  {i !== a.length - 1 && (
                    <>
                      <br></br>
                      <br></br>
                    </>
                  )}
                </React.Fragment>
              ))}
            </Box>

            <StotraList stotraData={stotraData} handleClickSlok={handleClickSlok} />

            {/* {relatedStotraList.length > 0 && (
              <RelatedStotra
                stotraList={relatedStotraList}
                handleClick={handleClickRelatedBooks}
              />
            )} */}
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default IndividualStotra;
