import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Stack,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import useCustomTable from "./useCustomTable";
import TablePaginationActions from "./TablePaginationActions";
import { useContextMenu } from "components/ContextMenu";

import FilterBox from "./FilterBox";
import styles from "./index.styles";
import { useCallback } from "react";

//TODO
//inspired from material-table
//TODO documentation
/**
 *
 * @param {data}
 * @param {option}
 * @param {columns}
 * @param {handleclick}- a function to handle the click funtion on each row. Should expect row data as parameter
 *
 * if only single data to be displayed then use
 * columns={{title="Single header",field="customField"}},
 * data={["first Data","second Data"]}
 *
 */

//TODO table style overrides feature

//TODO search Problem imposed by _id and return
//TODO make use of Return in columns

//TODO problem on sorting for multiple depency render fields;
const RangaTable = React.memo(
  ({
    id,
    filters,
    data,
    options,
    columns,
    actions, //TODO use this to handle actions like delete,edit,save
    handleClick,
    handleHover,
    handleClickColumn,
    onPageChange,
    onContextMenu,
    addRow,
    onRowAdd,
    filterOptions = [],
    size = "medium",
    bordered = false,
    radius = "10px",
    Extra = () => <></>,
    Extra2 = () => <></>,
    onChangeFilter,
    breakPoint = "sm",
    ...otherProps
  }) => {
    const [newData, setNewData] = useState({});

    const openContextMenu = useContextMenu(onContextMenu);

    //Making sure only the required Data are processed further
    const requiredData = useMemo(() => {
      //if only one data then...
      //TODO allow for multiple arrays of fields
      if (columns.length === 1 && typeof data[0] !== "object") {
        return data.map((each) => {
          return { [columns[0].field]: each };
        });
      }
      const columnWithFieldOrReturnProp = columns.filter(
        (column) => column.field || column.return
      );

      //TODO ***Check this (may be a better way) */
      const requiredFieldsAndReturnArr = columnWithFieldOrReturnProp
        .map((column) => (column.return ? [column.return, column.field] : column.field))
        .flat(2);
      const requiredFieldsSet = new Set(requiredFieldsAndReturnArr);
      const uniqueRequiredFields = [...requiredFieldsSet];
      //** --------Check this  ends here------- */

      return data.map((data) =>
        uniqueRequiredFields.reduce(
          (obj, field) => {
            return { ...obj, [field]: data[field] };
          },
          { _id: data._id }
        )
      );
    }, [columns, data]);

    const {
      sortAbility = true,
      filterAbility,
      snDisplay,
      disablePagination = false,
      statusToggle = false,
      showAll = false,
      rowClickabbleOptions = [],
      cursor = "pointer",
      tableRowID = "",
    } = options;

    const defaultSort = columns?.find((column) => column.defaultSort);
    const { sorted, sort, sortConfig, filtered, filter, searchConfig } = useCustomTable(
      requiredData,
      defaultSort?.field || null,
      defaultSort?.defaultSort || "asc",
      sortAbility, // these two sortability and filterability are passed to prevent some extra cacluation (maybe)
      filterAbility
    );

    /**Pagination Setup Starts */
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);

      if (onPageChange) {
        const count = displayData.length;
        const isLast = newPage >= Math.ceil(count / rowsPerPage) - 1;
        onPageChange(newPage, isLast);
      }
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect(() => {
      setPage(0);
    }, [filters]);

    useEffect(() => {
      setPage(0);
    }, [searchConfig.input]);
    /**Pagination Setup ends */

    const displayData = useMemo(() => {
      let data = filtered || sorted;

      return data;
    }, [filtered, sorted]);

    const rowsPerPageOptions = !onPageChange
      ? [10, 20, 50, { label: "All", value: -1 }]
      : [10, 20, 50];

    const isClickable = useCallback((row) => {
      if (rowClickabbleOptions.length === 0 && handleClick) return true;
      if (rowClickabbleOptions.length === 0 && !handleClick) return false;

      return rowClickabbleOptions.some((each) => {
        if (typeof row?.[each] === "object") {
          return row?.[each].length > 0;
        } else if (typeof row?.[each] === "boolean") {
          return row?.[each];
        }

        return !!handleClick;
      });
    }, []);

    const onClick = (row, e) => {
      if (!isClickable(row)) return;

      const returnable = columns.reduce(
        (obj, column) => {
          const key = column.return || column.field;
          return { ...obj, [key]: row[key] };
        },
        { _id: row._id }
      );
      handleClick && handleClick(returnable, e);
    };

    const TableData = ({ row }) => {
      return (
        <>
          {columns.map((column, i) => {
            if (column.hidden) return null;
            return (
              <TableCell
                key={i}
                sx={[
                  {
                    ...styles.tableData,
                    padding: column?.hidden && 0,
                    fontWeight: row?.fontWeight || "normal",
                    ...(column?.style || {}),
                  },
                  bordered && styles.bordered,
                  !bordered && styles.borderLess,
                ]}
              >
                {column.render
                  ? column.render(row, handleClickColumn)
                  : column?.isPrice
                  ? row[column.field].toLocaleString("en-IN")
                  : row[column.field]}
              </TableCell>
            );
          })}
        </>
      );
    };

    const NewRowAdd = () => (
      <>
        {snDisplay && (
          <TableCell sx={bordered ? styles.bordered : styles.borderLess}>
            {data.length + 1}
          </TableCell>
        )}
        {columns.map((column, index) => (
          <TableCell sx={bordered ? styles.bordered : styles.borderLess} key={index}>
            <input
              autoFocus
              onChange={(e) => {
                setNewData({
                  ...newData,
                  [column.field]: e.currentTarget.value,
                });
              }}
              style={styles.addMoreInput}
              value={newData[column.field]}
              onKeyDown={(e) => {
                if (e.which === 13) {
                  if (newData[column.field]) onRowAdd(newData);
                }
              }}
              onBlur={() => {
                if (newData[column.field]) onRowAdd(newData);
              }}
            />
          </TableCell>
        ))}
      </>
    );

    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: filterAbility ? "max-content auto" : "auto",
          overflow: { [breakPoint]: "hidden" },
          height: { [breakPoint]: "100%" },
        }}
      >
        {filterAbility && (
          <Stack direction="row" gap={2} alignItems="center" py={2}>
            <FilterBox
              id={id}
              isSticky={otherProps.isSticky}
              filter={filter}
              onChangeFilter={onChangeFilter}
            />

            {<Extra2 />}
          </Stack>
        )}
        <TableContainer
          sx={{
            borderRadius: radius,
            overflowY: { xs: "hidden", sm: "auto" },
            overflowX: "auto",
          }}
        >
          <>
            <Table size={size} stickyHeader sx={styles.tableContentRoot}>
              <TableHead>
                <TableRow>
                  {snDisplay && (
                    <TableCell
                      sx={[
                        { width: "10px", ...styles.tableHead },
                        styles.tableHead,
                        bordered && styles.bordered,
                        !bordered && styles.borderLess,
                      ]}
                    >
                      S.No.
                    </TableCell>
                  )}
                  {columns.map((column, index) => {
                    if (column.hidden) return null;
                    return (
                      <TableCell
                        key={index}
                        sx={[
                          { ...styles.tableHead, padding: column?.hidden && 0 },
                          styles.tableHead,
                          bordered && styles.bordered,
                          !bordered && styles.borderLess,
                          !column.actionColumn && styles.headCell,
                          !column.actionColumn && styles.tableHead,
                        ]}
                        onClick={() => {
                          sortAbility && !column.actionColumn && sort(column.field);
                        }}
                      >
                        {column.actionColumn ? column.title() : column.title}
                        {!column.actionColumn && sortAbility && (
                          <ArrowUpwardIcon
                            fontSize="small"
                            sx={
                              sortConfig.key === column.field && !sortConfig.ascending
                                ? styles.rotatedSvg
                                : {}
                            }
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {(showAll
                  ? displayData
                  : rowsPerPage > 0 && !disablePagination
                  ? displayData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : displayData
                ).map((row, index) => {
                  const _sn = row?.sn === false ? row?.sn : true; //

                  return (
                    <TableRow
                      onContextMenu={(e) => onContextMenu && openContextMenu(e, row)}
                      key={index}
                      id={tableRowID ? row?.[tableRowID] : ""}
                      sx={[
                        {
                          cursor,
                          backgroundColor: row?.rowColor,
                        },
                        styles.tableRow,
                      ]}
                      onClick={(e) => onClick(row, e)}
                    >
                      {snDisplay && (
                        <TableCell sx={bordered ? styles.bordered : styles.borderLess}>
                          {!_sn ? "" : page * rowsPerPage + index + 1}
                        </TableCell>
                      )}
                      <TableData row={row} index={index} />
                    </TableRow>
                  );
                })}
                <TableRow>{addRow && NewRowAdd()}</TableRow>
                {/* //TODO Pagination Check */}
              </TableBody>

              {!showAll && displayData.length >= 50 && !disablePagination && (
                <TableFooter>
                  <TableRow sx={{ "& > td": { overflow: "visible" } }}>
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions}
                      colSpan={3}
                      count={displayData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </>
        </TableContainer>
      </Box>
    );
  }
);

RangaTable.propTypes = {
  options: PropTypes.shape({
    sortAbility: PropTypes.bool,
    filterAbility: PropTypes.bool,
    snDisplay: PropTypes.bool,
    disablePagination: PropTypes.bool,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired, //TODO func check
      field: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      actionColumn: PropTypes.bool,
      render: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]), //TODO func check
    })
  ).isRequired,
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.array])
    .isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element])
  ),
  handleClick: PropTypes.func,
  addRow: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  bordered: PropTypes.bool,
};
export default RangaTable;
