function fetchy(url, method, data) {
  return new Promise((resolve, reject) => {
    if (typeof data === "object" || typeof data === "undefined") {
      fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          "X-Csrftoken": localStorage.csrftoken,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.forceActions) {
            res.forceActions && console.warn(res.forceActions);
            res.forceActions.redirectToLogin &&
              window.location.pathname !== "/" &&
              (window.location.pathname = "/");
          }

          if (res.error) {
            res.error && console.error(res.error);
            resolve({ success: false, error: res.error });
          } else {
            resolve(res);
          }
        })
        .catch((e) => {
          console.error("network problems");
          // handle offline alerts here
        });
    } else {
      console.error("data field must be either blank or JSON, others not supported");
      reject();
    }
  });
}

export default fetchy;
