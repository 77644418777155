import Home from "views/Home";
import StotraDetails from "views/Stotra/StotraDetails";
import About from "views/About";
import AllStotra from "views/Stotra/AllStotra";
import IndividualStotra from "views/Stotra/IndividualStotra";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/allStotra",
    element: <AllStotra />,
  },
  {
    path: "/stotra",
    element: <IndividualStotra />,
  },
  {
    path: "/stotra-details/:stotraID",
    element: <StotraDetails />,
  },
];

export { routes };
