import { Route, Routes, Navigate } from "react-router-dom";
import { routes } from "./routes";
import Initialize from "common/Initialize";

function AllRoutes() {
  Initialize();
  return (
    <>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element}>
            {(route?.subPath || []).map((subRoute) => (
              <Route
                key={subRoute.path}
                path={subRoute.path}
                element={subRoute.element}
              ></Route>
            ))}

            {route.redirectTo && (
              <Route path="" element={<Navigate to={route.redirectTo} />} />
            )}
          </Route>
        ))}
      </Routes>
    </>
  );
}

export default AllRoutes;
