import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Slider from "react-slick";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { booksStyles } from "./styles";
import "./index.css";

import book1 from "assets/book1.jpg";
import book2 from "assets/book2.jpg";
import book3 from "assets/book3.jpg";
import book4 from "assets/book4.jpg";
import book5 from "assets/book5.jpg";
import book6 from "assets/book6.jpg";
import book7 from "assets/book7.jpg";
import book8 from "assets/book8.jpg";

const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <ArrowForwardIos fontSize="large" />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <ArrowBackIos fontSize="large" />
    </div>
  );
};

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 6000,
  lazyLoad: true,
  swipeToSlide: false,
  draggable: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1180,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
  ],
};

const banners = [book1, book2, book3, book4, book5, book6, book7, book8];

function BooksBox() {
  const handleClick = (e) => {
    console.log("clicked");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFF0D0",
      }}
    >
      <Box sx={booksStyles.box}>
        <Typography sx={booksStyles.header}>विशेष पुस्तक</Typography>

        <Slider {...settings} autoplaySpeed={5000}>
          {banners.map((data) => (
            <Stack
              key={data}
              onClick={handleClick}
              sx={{
                px: 2,
                background: "transparent",
                cursor: "pointer",
              }}
              draggable="true"
            >
              <Box
                sx={{
                  px: 2,
                  pt: 2,
                  pb: 4,
                  background: "white",
                  position: "relative",
                  zIndex: -1,
                }}
              >
                <img alt="banner" src={data} style={{ width: "100%" }} />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "center",
                    pt: 2,
                  }}
                >
                  श्रीरामचरितमानस (सटीक)
                </Typography>
              </Box>
            </Stack>
          ))}
        </Slider>

        <Stack pb={2} pt={4} alignItems="center">
          <Button variant="contained" sx={{ width: "auto" }}>
            सम्पर्ण विशेष पुस्तक
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default BooksBox;
