import theme from "theme";

const styles = {
  root: {
    // marginBottom: "1rem",
    position: "relative",
    // height: "100%",
    // minHeight: "100%",
    visibility: "visible",

    // overflow: "hidden",
  },
  scrollable: {
    overflowY: "scroll",
    visibility: "hidden",
    "&:hover": {
      visibility: "visible",
    },
  },
  tableContentRoot: {
    visibility: "visible",
  },

  bordered: {
    border: "1px solid black",
    "&:hover": {
      background: "#f3f3f3 !important",
    },
  },
  borderLess: {
    // border: "none",
  },
  headCell: {
    cursor: "pointer",

    "& svg": {
      opacity: 0,
      position: "relative",
      top: 4,
      transition: theme.transitions.create(["opacity", "transform"]),
    },
    "&:hover": {
      // color: ,
      "& svg": {
        opacity: 1,
        transition: theme.transitions.create(["opacity", "transform"]),
      },
    },
  },
  rotatedSvg: {
    transition: theme.transitions.create("transform"),
    transform: "rotate(180deg)",
  },
  tableHead: {
    background: "#f3f3f3",
    fontWeight: 800,
  },

  tableRow: {
    // cursor: "pointer",
    "&:hover": {
      background: "#f3f3f3 !important",
    },
  },
  addMoreInput: {
    border: "none",
    borderBottom: "1px solid black",
  },
  addMoreCheckMark: {
    cursor: "pointer",
    verticalAlign: "middle",
    display: "table-cell",
  },
};

export default styles;
