import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import theme from "theme";

const styles = {
  icon: {
    "&:hover": {
      transition: theme.transitions.create("color"),
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
};

const FilterBox = (props) => {
  const { filter } = props;

  const inputRef = React.useRef();

  return (
    <>
      <TextField
        inputRef={inputRef}
        onChange={(e) => {
          filter(e.currentTarget.value);
        }}
        placeholder="Search"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={styles.icon} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <ClearIcon
                sx={styles.icon}
                fontSize="small"
                onClick={() => {
                  filter("");
                  inputRef.current.value = "";
                  inputRef.current.focus();
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default FilterBox;
