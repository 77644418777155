import { CalendarMonth } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

import dateData from "assets/dateDetail.json";
import { nepaliNums } from "utils";
import sunrise from "assets/sunrise.png";
import sunset from "assets/sunset.png";
import moment from "moment";
import { useSelector } from "react-redux";

function TodayDetail() {
  const config = useSelector((state) => state.config);
  const todayDate =
    dateData.find((d) =>
      moment(moment().format("YYYY/MM/DD")).isSame(
        moment(d.englishDate, "MMMM DD, YYYY").format("YYYY/MM/DD")
      )
    ) || {};
  const [sunriseAndSet, setSunriseAndSet] = React.useState();

  useEffect(() => {
    fetch("https://api.sunrisesunset.io/json?lat=27.675602&lng=85.311445")
      .then((res) => res.json())
      .then(({ results }) => {
        setSunriseAndSet({
          sunrise: results.sunrise
            .split(" ")
            .at(0)
            .split(":")
            .map((a) =>
              a
                .split("")
                .map((b) => nepaliNums[b])
                .join("")
            )
            .join(":"),
          sunset: results.sunset
            .split(" ")
            .at(0)
            .split(":")
            .map((a) =>
              a
                .split("")
                .map((b) => nepaliNums[b])
                .join("")
            )
            .join(":"),
        });
      });
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "1180px",
        margin: "0 auto",
      }}
    >
      <Stack direction={{ xs: "column", sm: "row" }} gap={2} my={2} px={2}>
        <Stack
          alignItems="center"
          sx={{ backgroundColor: "#FFF5D2", py: 4, px: 2, borderRadius: "10px" }}
          flex={1}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            आजको श्लोक
          </Typography>
          <Stack alignItems="center">
            <Typography py={2} style={{ color: "#D11501", fontWeight: "bold" }}>
              {config?.today_slok?.slok_name}
            </Typography>
            <Typography sx={{ color: "#DF2C0B", fontSize: "14px" }}>
              {config?.today_slok?.slok_meaning}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          alignItems="center"
          sx={{ backgroundColor: "#F4FBFF", py: 4, px: 2, borderRadius: "10px" }}
          flex={1}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "20px", color: "#FF4E2A" }}>
            आजको दिनको महत्त्व
          </Typography>

          <Stack
            width="100%"
            direction="row"
            justifyContent="space-around"
            py={2}
            sx={{
              borderBottom: "2px solid #FB8C1C",
            }}
          >
            <Stack
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              gap={1}
              sx={{
                borderRight: "2px solid #FB8C1C",
              }}
            >
              <CalendarMonth fontSize="large" sx={{ color: "lightblue" }} />
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "14px", xsa: "16px" },
                    textAlign: "center",
                  }}
                >
                  {todayDate.nepaliDate}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "14px", xsa: "16px" },
                    textAlign: "center",
                  }}
                >
                  {todayDate.englishDate}
                </Typography>
              </Box>
            </Stack>

            <Stack
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              gap={1}
              sx={{
                borderRight: "2px solid #FB8C1C",
              }}
            >
              <Stack alignItems="center" gap={0.5}>
                <img
                  src={sunrise}
                  style={{ height: "35px", width: "35px", borderRadius: "100%" }}
                />
                <Typography sx={{ fontSize: "8px" }}>सूर्योदय समय</Typography>
              </Stack>
              <Typography
                sx={{ fontWeight: "bold", fontSize: { xs: "14px", xsa: "16px" } }}
              >
                {sunriseAndSet?.sunrise}
              </Typography>
            </Stack>
            <Stack flex={1} alignItems="center" justifyContent="space-between" gap={1}>
              <Stack alignItems="center" gap={0.5}>
                <img
                  src={sunset}
                  style={{ height: "35px", width: "35px", borderRadius: "100%" }}
                />
                <Typography sx={{ fontSize: "8px" }}>सूर्यास्त समय</Typography>
              </Stack>
              <Typography
                sx={{ fontWeight: "bold", fontSize: { xs: "14px", xsa: "16px" } }}
              >
                {sunriseAndSet?.sunset}
              </Typography>
            </Stack>
          </Stack>
          <Typography
            className="chanakya_font"
            sx={{ color: "#DF2C0B", pt: 2, fontSize: "20px", lineHeight: "1" }}
          >
            {todayDate.panchanga.at(0).trim().split("\n\n").join(", ")}
          </Typography>
          <Typography
            className="chanakya_font"
            sx={{ color: "#DF2C0B", fontSize: "20px", lineHeight: "1" }}
          >
            {todayDate.event}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default TodayDetail;
