import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Slider from "react-slick";
import { ArrowBackIos, ArrowForwardIos, Lyrics } from "@mui/icons-material";
import { topAudioboxStyles, topVideoBoxStyles } from "./styles";
import "./index.css";

import book1 from "assets/book1.jpg";
import book2 from "assets/book2.jpg";
import book3 from "assets/book3.jpg";
import book4 from "assets/book4.jpg";
import book5 from "assets/book5.jpg";
import book6 from "assets/book6.jpg";
import book7 from "assets/book7.jpg";
import book8 from "assets/book8.jpg";

const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <ArrowForwardIos fontSize="large" />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <ArrowBackIos fontSize="large" />
    </div>
  );
};

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 6000,
  lazyLoad: true,
  swipeToSlide: false,
  draggable: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1180,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const banners = [book1, book2, book3, book4, book5, book6, book7, book8];

function TopVideoBox() {
  const handleClick = (e) => {
    console.log("clicked");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFF0D0",
      }}
    >
      <Box sx={topVideoBoxStyles.box}>
        <Typography sx={topVideoBoxStyles.header}>विशेष भिडियो</Typography>

        <Slider {...settings} autoplaySpeed={5000}>
          {banners.map((data) => (
            <Stack
              key={data}
              onClick={handleClick}
              sx={{
                px: 2,
                py: 0.5,
                background: "transparent",
                cursor: "pointer",
                position: "relative",
                zIndex: 2,
              }}
              draggable="true"
            >
              <Stack
                alignItems="center"
                sx={{ background: "#ffc72f", p: 3, boxShadow: 3, borderRadius: "10px" }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    borderRadius: "100px",
                    p: 1,
                    width: "50px",
                    height: "50px",
                    background: "#FFA52F",
                  }}
                >
                  <Lyrics sx={{ color: "white" }} />
                </Stack>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "center",
                    pt: 2,
                  }}
                >
                  वदिक साहित्य सम्बद्ध अडियो
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Slider>

        <Stack pb={2} pt={4} alignItems="center">
          <Button variant="contained" sx={{ width: "auto" }}>
            सम्पर्ण विशेष भिडियोहर
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default TopVideoBox;
