import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Divider, IconButton, ListItemIcon, Stack, Typography } from "@mui/material";
import {
  Audiotrack,
  Home,
  Inbox,
  Info,
  LibraryBooks,
  Mail,
  Menu,
  OndemandVideo,
  School,
  VolunteerActivism,
} from "@mui/icons-material";

import logo from "assets/logo.png";
import { useNavigate } from "react-router-dom";

const oo = [
  {
    title: "होम",
    path: "/",
    Icon: <Home color="primary" />,
  },
  {
    title: "परिचय",
    path: "/about",
    Icon: <Info color="primary" />,
  },
  {
    title: "वैदिक",
    path: "",
    Icon: <Home color="primary" />,
  },
  {
    title: "लौकिक",
    path: "/allStotra",
    Icon: <Home color="primary" />,
  },
  {
    title: "शब्दकोश",
    path: "",
    Icon: <LibraryBooks color="primary" />,
  },
  {
    title: "गुरुकुल",
    path: "",
    Icon: <School color="primary" />,
  },
  {
    title: "अडियो",
    path: "",
    Icon: <Audiotrack color="primary" />,
  },
  {
    title: "भिडियो",
    path: "",
    Icon: <OndemandVideo color="primary" />,
  },
  {
    title: "दान",
    path: "",
    Icon: <VolunteerActivism color="primary" />,
  },
];

export default function NavBarDrawer() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{ width: 250, backgroundColor: "secondary.main", height: "100%" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Stack alignItems="center" py={1}>
        <img src={logo} style={{ height: "75px" }} />
        <Typography>VAG</Typography>
      </Stack>

      <List>
        {oo.map((option, index) => (
          <React.Fragment key={index}>
            <ListItem
              disablePadding
              onClick={() => {
                if (!option.path) return;
                navigate(option.path);
              }}
            >
              <ListItemButton>
                <ListItemIcon>{option.Icon}</ListItemIcon>
                <ListItemText
                  primary={option.title}
                  primaryTypographyProps={{
                    sx: { fontWeight: "bold" },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ color: "white", width: "90%", margin: "0 auto" }} />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        display: { xs: "block", xsa: "none" },
        position: "absolute",
        top: 5,
        right: 0,
      }}
    >
      <IconButton onClick={toggleDrawer(true)}>
        <Menu color="primary" fontSize="large" />
      </IconButton>

      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {DrawerList}
      </Drawer>
    </Box>
  );
}
