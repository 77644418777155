import React, { useState } from "react";

import {
  Box,
  Button,
  Input,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { contactStyles } from "./styles";

function Contact() {
  const [value, setValue] = useState({
    message: "",
  });

  return (
    <Box>
      <Typography sx={contactStyles.header}>सम्पक गर्नुहोस</Typography>
      <Stack gap={2}>
        <Stack direction={{ xs: "column", xsa: "row" }} gap={2}>
          <Input
            fullWidth
            disableUnderline={true}
            sx={{
              background: "white",
              px: 1.5,
              py: 0.5,
              borderRadius: "6px",
              fontWeight: "normal",
            }}
            placeholder="नाम"
          />

          <Input
            fullWidth
            disableUnderline={true}
            sx={{
              background: "white",
              px: 1.5,
              py: 0.5,
              borderRadius: "6px",
              fontWeight: "normal",
            }}
            placeholder="मोबाइल नम्बर"
          />
        </Stack>
        <Stack direction={{ xs: "column", xsa: "row" }} gap={2}>
          <Box flex={1}>
            <Input
              fullWidth
              disableUnderline={true}
              sx={{
                background: "white",
                px: 1.5,
                py: 0.5,
                borderRadius: "6px",
                fontWeight: "normal",
              }}
              placeholder="ई-मेल"
            />
          </Box>

          <CategorySelect />
        </Stack>

        <TextareaAutosize
          value={value.message}
          onPaste={(e) => {
            if (e.target.value.length > 500) {
              e.preventDefault();
              return;
            }
          }}
          onChange={(e) => {
            if (e.target.value.length > 500) return;
            setValue((prev) => ({ ...prev, message: e.target.value }));
          }}
          minRows={5}
          style={{
            border: 0,
            outline: "none",
            borderRadius: "6px",
            resize: "none",
            fontSize: "18px",
            fontFamily: "inherit",
            padding: "16px",
          }}
        />
        <Typography sx={{ fontSize: "12px" }}>
          {value.message.length === 500 ? "" : `शेष अक्षर  ${500 - value.message.length}`}
        </Typography>

        <Stack alignItems="flex-start">
          <Button variant="contained" sx={{ width: "auto" }}>
            पठाउनुहोस
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

const options = ["General Enquiry", "Audios", "Videos", "Books"];

const CategorySelect = () => {
  const [age, setAge] = React.useState("General Enquiry");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box flex={1}>
      <Select
        value={age}
        onChange={handleChange}
        input={
          <Input
            fullWidth
            disableUnderline={true}
            sx={{
              background: "white",
              px: 1.5,
              py: 0.5,
              borderRadius: "6px",
              fontWeight: "normal",
            }}
            placeholder="ई-मेल"
          />
        }
      >
        {options.map((o) => (
          <MenuItem key={o} value={o}>
            {o}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default Contact;
