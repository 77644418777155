const initialState = {};

export default function config(state = initialState, { type, payload }) {
  switch (type) {
    case "POPULATE_CONFIG_DATA":
      return payload;
    default:
      return state;
  }
}
