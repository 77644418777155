import React, { useMemo } from "react";
import { Box, Stack, Typography, Input, Pagination } from "@mui/material";
import theme from "theme";
import { Search } from "@mui/icons-material";
import SortDropDown from "components/UI/SortDropDown";
import { sortOptions, stotraList } from "./setup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import book1 from "assets/book2.jpg";

function StotraBox() {
  const [query] = useSearchParams();
  const stotraData = useSelector((state) => state.stotra);
  const config = useSelector((state) => state.config);

  const navigate = useNavigate();
  const [value, setValue] = React.useState({
    sortBy: "नवीनतम",
    page: 0,
    search: "",
  });

  const category = query.get("category") || "सबै";

  const filteredStotraData = useMemo(() => {
    if (category === "सबै") return [...new Set(stotraData.map((s) => s.book_name))];
    return [
      ...new Set(
        stotraData.filter((s) => s.category === category).map((s) => s.book_name)
      ),
    ];
  }, [value.sortBy, stotraData, value.search, category]);

  const displayData = useMemo(() => {
    return filteredStotraData.slice(value.page * 12, value.page * 12 + 12);
  }, [value.page, filteredStotraData]);

  const handleChangePage = (_, value) => {
    setValue((prev) => ({ ...prev, page: value - 1 }));
  };

  const handleClickList = (list) => {
    navigate(`/stotra?category=${category}&book=${list}`);
  };

  return (
    <>
      <Box
        className="box-shadow-3"
        sx={{
          backgroundColor: theme.dimmerYellow,
          p: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
          flexWrap="wrap"
          gap={{ xs: 2, mda: 0 }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            जम्मा सङ्ख्या : {filteredStotraData.length}
          </Typography>

          <Input
            size="small"
            endAdornment={<Search color="secondary" />}
            disableUnderline={true}
            className="box-shadow-3"
            inputProps={{
              style: {
                padding: "4px 0",
              },
            }}
            sx={{
              background: "white",
              borderRadius: "4px",
              border: "1px solid #ffa843",
              px: 1,
              width: { xs: "100%", xsa: "auto" },
              "& > input::placeholder": {
                fontSize: "12px",
              },
            }}
            placeholder="भित्र खोज्नुहोस्"
          />

          <Stack direction="row" gap={2} alignItems="center">
            <Typography sx={{ fontWeight: "bold" }}>क्रमबद्ध गर्नुहोस्</Typography>
            <SortDropDown
              value={value.sortBy}
              options={sortOptions}
              handleChange={(v) => setValue((prev) => ({ ...prev, sortBy: v.value }))}
            />
          </Stack>
        </Stack>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(max(165px, 100%/5), 1fr))",
            gap: 2,
          }}
        >
          {displayData.map((list) => (
            <Stack
              onClick={() => handleClickList(list)}
              alignItems="center"
              justifyContent="center"
              key={list}
              sx={{
                backgroundColor: "white",
                py: 2,
                gap: 2,
                boxShadow: 1,
                cursor: "pointer",
                "&:hover": {
                  "& > p": {
                    color: "primary.main",
                  },
                },
              }}
            >
              <img
                src={book1}
                style={{ width: "150px", height: "150px", objectFit: "contain" }}
              />
              <Typography
                sx={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  textAlign: "center",
                  px: 1,
                }}
              >
                {list}
              </Typography>
            </Stack>
          ))}
        </Box>
        {filteredStotraData.length > 12 && (
          <Stack alignItems="flex-end" py={2}>
            <Pagination
              count={filteredStotraData.length / 12}
              color="primary"
              onChange={handleChangePage}
              // variant="outlined"
              shape="rounded"
            />
          </Stack>
        )}
      </Box>
    </>
  );
}

export default StotraBox;
