import React from "react";
import { Box } from "@mui/material";

import Header from "components/Header";
import Footer from "components/Footer";

import TopStrotra from "./TopStrotra";
import AudioBox from "./AudioBox";
import HomeSlider from "./HomeSlider";
import { homeStyles } from "./styles";
import AllStotraBox from "./AllStotraBox";
import BooksBox from "./BooksBox";
import TopAudioBox from "./TopAudioBox";
import TopVideoBox from "./TopVideoBox";
import GranthaBibaranBox from "./GranthaBibaranBox";
import About from "./About";
import Contact from "./Contact";
import TodayDetail from "./TodayDetail";

import headerbg from "assets/header-bg.jpg";

function Home() {
  return (
    <>
      <Box
        sx={{
          background: `url(${headerbg}) no-repeat top`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <Header />

        <Box sx={homeStyles.main}>
          <Box sx={homeStyles.sliderBox}>
            <HomeSlider />

            <AudioBox />
          </Box>

          <TopStrotra />
        </Box>

        <TodayDetail />

        <AllStotraBox />

        <BooksBox />

        <TopAudioBox />
        <TopVideoBox />
        <GranthaBibaranBox />

        <Box sx={homeStyles.aboutContactBoxMain}>
          <Box sx={homeStyles.aboutContactBox}>
            <About />

            <Contact />
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default Home;
