const nepaliNums = {
  0: "०",
  1: "१",
  2: "२",
  3: "३",
  4: "४",
  5: "५",
  6: "६",
  7: "७",
  8: "८",
  9: "९",
  ":": ":",
};

const convertArrayToObject = (array, key = "id") => {
  return array.reduce((obj, item) => {
    return {
      [item[key]]: item,
      ...obj,
    };
  }, {});
};

export { nepaliNums, convertArrayToObject };
