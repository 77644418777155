import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "theme";
import ScrollToTop from "./components/ScrollToTop";
import store from "reduxStore";
import { ContextMenuProvider } from "components/ContextMenu";

const customTheme = responsiveFontSizes(theme);

const Providers = ({ children }) => (
  <Provider store={store}>
    <ThemeProvider theme={customTheme}>
      <ContextMenuProvider>
        <BrowserRouter>
          <ScrollToTop>{children}</ScrollToTop>
        </BrowserRouter>
      </ContextMenuProvider>
    </ThemeProvider>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Providers>
      <App />
      <CssBaseline />
    </Providers>
  </React.StrictMode>
);
