import { stotraController } from "fetchy";

const fetchStotra = () => {
  return async (dispatch) => {
    stotraController.getAllStotra().then(({ data, error }) => {
      if (data) {
        dispatch({
          type: "POPULATE_STOTRA_DATA",
          payload: data,
        });
      }
    });
  };
};

export { fetchStotra };
