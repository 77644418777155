import React from "react";

import { Box, Typography } from "@mui/material";
import { aboutStyles } from "./styles";
import vagTrust from "assets/vag-trust.png";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={aboutStyles.header}>वदिक अनुसन्धान गुठी (वाग्)</Typography>
      <img src={vagTrust} style={{ width: "100%" }} />
      <Typography sx={aboutStyles.title}>वदिक अनुसन्धान गुठी (वाग्)</Typography>
      <div className="chanakya_font" style={{ textAlign: "justify", lineHeight: "30px" }}>
        <span
          style={{
            fontSize: "22px",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
          }}
        >
          &nbsp; भगवान ने कहा है <strong>‘ममैवांशो जीवलोके</strong>
          <strong>’</strong> - यह जीव मेरा ही अंश है। प्राणीमात्र सच्चिदानन्द परमात्माके
          अंश हैं। सभी प्राणियोंमें केवल मनुष्य ही ऐसा है जो अपने विवेकका प्रयोग करके
          परमात्माको प्राप्त कर सकता है। सच्चिदानन्दका अंश होकर भी आज जगत् दुःखी हो रहा
          है, इसमें एकमात्र कारण अपने विवेकका दुरुपयोग है। वास्तवमें काम, क्रोध, लोभ,
          ईर्ष्या, अभिमान आदिके कारण ही मनुष्य दुःखी हो रहा है।&nbsp;
        </span>
      </div>
      <Typography onClick={() => navigate("/about")} sx={aboutStyles.nextText}>
        अरू पढ्नुहोस
      </Typography>
    </Box>
  );
}

export default About;
