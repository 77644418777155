import React from "react";

import { Box, Stack } from "@mui/material";

import Footer from "components/Footer";
import Header from "components/Header";
import PathHeader from "components/UI/PathHeader";

import SideNav from "./SideNav";
import styles from "./styles";
import StotraBox from "./StotraBox";

function AllStotra() {
  return (
    <>
      <Header />

      <Box sx={styles.mainBox}>
        <Box sx={styles.subBox}>
          <PathHeader header="स्तोत्रहरू" subPath="गृह / लौकिक / स्तोत्रहरू" />

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "auto", sm: "max-content auto" },
              gap: 4,
            }}
          >
            <SideNav />

            <StotraBox />
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default AllStotra;
