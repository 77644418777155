import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Stack } from "@mui/material";

const NextArrow = ({ onClick }) => {
  return (
    <div className={`arrow next fw`}>
      <ArrowForwardIos onClick={onClick} sx={{ color: "black" }} fontSize="large" />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className={`arrow prev fw`}>
      <ArrowBackIos onClick={onClick} sx={{ color: "black" }} fontSize="large" />
    </div>
  );
};

const settings = {
  initialSlide: 0,
  slidesToScroll: 1,
  infinite: true,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const tableHeader = [
  {
    title: "नाम",
    field: "name",
  },
  {
    title: "श्लोक",
    field: "slok",
    render: ({ slok }) => (
      <Stack>
        {slok.split("\n").map((s) => (
          <p key={s}>{s}</p>
        ))}
      </Stack>
    ),
  },
  {
    title: "id",
    field: "id",
    hidden: true,
  },
];

export { settings, tableHeader };
