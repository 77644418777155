import React from "react";

/**
 *
 * @param {Array} items - Array of objects that needs the functionality of useCustomTable
 * @param {String} defaultKey -(optional) A key that sorts thae above array
 *
 * eg: items=> [{name:"ranga",location:"Dhulikhel"},{name:"junee",Location:"Kathmandu"}]
 * Now if you want the above items to be sorted with key as "name" then simply pass defaultKey as "name"
 *
 *  const { sorted, sort, sortConfig, filtered, filter } = useCustomTable(items,"name");
 * console.log(sorted) // [{name:"junee",locaion:"kathmandu"}, {name:"ranga",loaiton:"Dhulikhel"} ]
 *
 * @returns {Object} - Look below :p
 *
 * Pass the key by which the items need to be sorted/filter in sort/filter function
 *
 */
const useCustomTable = (
  items,
  defaultKey = null,
  defaultDirection = "asc",
  sortAbility,
  filterAbility
) => {
  const [sortConfig, setSortConfig] = React.useState({
    key: defaultKey,
    ascending: defaultDirection === "asc" ? true : false,
  });
  const [searchConfig, setSearchConfig] = React.useState({
    key: null,
    input: null,
  });

  const sorted = React.useMemo(() => {
    // if (sortAbility) {
    let sortedData = [...items];
    if (sortConfig.key !== null) {
      sortedData.sort((a, b) => {
        const x = +a[sortConfig.key] || a[sortConfig.key];
        const y = +b[sortConfig.key] || b[sortConfig.key];
        if (x < y) {
          return sortConfig.ascending ? -1 : 1;
        }
        if (x > y) {
          return sortConfig.ascending ? 1 : -1;
        } else return 0;
      });
    }
    return sortedData;
    // }
  }, [items, sortConfig]);

  const filtered = React.useMemo(() => {
    // if (filterAbility) {
    if (searchConfig.key !== null) {
      return items.filter((item) =>
        item[searchConfig.key]
          .toString()
          .toUpperCase()
          .includes(searchConfig.input.toUpperCase())
      );
    } else {
      return searchConfig.input
        ? items.filter((item) =>
            JSON.stringify(item).toUpperCase().includes(searchConfig.input.toUpperCase())
          )
        : "";
    }
    // }
  }, [items, searchConfig]);

  /**
   *
   * @param {String} key -accepst the field by which the overall data needs to be sorted
   * @param {Bool} direction (optional) - accepts "asc" for ascending else assumes descending
   */
  const sort = (key, direction = null) => {
    let isAscending;
    if (direction) {
      isAscending = direction === "asc" ? true : false;
    } else isAscending = !sortConfig.ascending;
    setSortConfig({ key, ascending: isAscending });
  };

  /**
   *
   * @param {String} input - accepts the value that needs to be scanned throughout the data
   * @param {String} key  - accepts the field to restrict the scan wihtin that field
   */
  const filter = (input, key = null) => {
    setSearchConfig({ key, input });
  };

  return { sorted, sort, sortConfig, filtered, filter, searchConfig };
};

export default useCustomTable;
