import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

import { topStotras } from "./setup";
import { topStotraStyles } from "./styles";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function TopStrotra() {
  const navigate = useNavigate();
  const config = useSelector((state) => state.config);

  const handleClickEach = (o) => {
    navigate(`/stotra?category=सबै&book=${o.name}`);
  };

  return (
    <Box sx={{ p: { xs: 2, md: 0 } }}>
      <Box sx={topStotraStyles.box}>
        <Typography className="chanakya_font" s sx={topStotraStyles.header}>
          विशेष स्तोत्रहरू
        </Typography>
      </Box>

      <Box sx={topStotraStyles.itemBox}>
        {(config?.books || []).slice(0, 3).map((o, i) => (
          <ItemUI key={i} data={o} handleClickEach={() => handleClickEach(o)} />
        ))}

        <Stack pb={2} alignItems="center">
          <Button
            variant="contained"
            sx={{ width: "auto" }}
            onClick={() => navigate("/allStotra")}
          >
            सम्पर्ण स्तोत्रहरू
          </Button>
        </Stack>

        <Box sx={{ border: "2px solid #d11501" }} />
      </Box>
    </Box>
  );
}

const ItemUI = ({ data, handleClickEach }) => {
  return (
    <Box
      sx={{
        "&:nth-of-type(1)": {
          pt: { xs: 4, md: 0 },
        },
      }}
    >
      <Typography sx={topStotraStyles.title} className="chanakya_font">
        {data.name}
      </Typography>

      <Typography
        className="chanakya_font"
        sx={{
          ...topStotraStyles.slok,
          pb: 0,
          display: "-webkit-box",
          WebkitLineClamp: 3,
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          fontSize: "18px",
          letterHeight: "1",
        }}
      >
        {data.description}
      </Typography>

      <Typography pt={1} onClick={handleClickEach} sx={topStotraStyles.nextText}>
        अरू पढ्नुहोस
      </Typography>
    </Box>
  );
};

export default TopStrotra;
