import React, { useState, useEffect, useRef } from "react";

import "./index.styles.css";
import useContextMenu from "./useContextMenu";

const StoreContext = React.createContext([{}, () => {}]);

const useClickAwayListener = (ref) => {
  const [isClickAway, setIsClickAway] = useState(false);
  useEffect(() => {
    function handleClickAway(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsClickAway(true);
      }
    }
    document.addEventListener("mousedown", handleClickAway);
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [ref]);
  return [isClickAway, setIsClickAway];
};

const ContextMenuProvider = ({ children }) => {
  const [state, setState] = useState({});

  return (
    <StoreContext.Provider value={[state, setState]}>
      {children}
      <ContextMenu state={state} setState={setState} />
    </StoreContext.Provider>
  );
};

function ContextMenu({ state, setState }) {
  const { visible, ref, items, data } = state;
  const contextRef = useRef();
  const [isClickAway, setIsClickAway] = useClickAwayListener(contextRef);
  const [positionX, setPositionX] = useState(-9999);
  const [positionY, setPositionY] = useState(-9999);

  useEffect(() => {
    if (isClickAway) {
      setState({ ...state, visible: false });
      setIsClickAway(false);
      setPositionX(-9999);
      setPositionY(-9999);
    }
    //eslint-disable-next-line
  }, [isClickAway, state]);

  //gets exact posiiton of element
  //@param {object} e the event  passed in
  const getPosition = (e) => {
    var posx = 0;
    var posy = 0;

    if (!e) e = window.event;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    return {
      x: posx,
      y: posy,
    };
  };

  //Correctly places the position of context menu considering the overflow near the edges of window
  //@param {object} e=>event thst's passed in
  const positionMenu = (e) => {
    let clickCoords = getPosition(e);

    let clickCoordsX = clickCoords.x;
    let clickCoordsY = clickCoords.y;
    if (contextRef?.current) {
      const menu = contextRef.current;
      let menuWidth = menu.offsetWidth + 10;
      let menuHeight = menu.offsetHeight + 4;

      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      if (windowWidth - clickCoordsX < menuWidth) {
        const x = windowWidth - menuWidth;
        setPositionX(x);
      } else {
        setPositionX(clickCoordsX);
        //   setSubPositionX(menuWidth - 18);
      }
      if (windowWidth - clickCoordsX < 2 * menuWidth) {
        //   setSubPositionX(-menuWidth + 8);
      }

      if (windowHeight - clickCoordsY < menuHeight) {
        setPositionY(windowHeight - menuHeight);
      } else {
        setPositionY(clickCoordsY);
      }
    }
  };

  useEffect(() => {
    if (ref) positionMenu(ref);
    //eslint-disable-next-line
  }, [ref]);

  let parentStyle = {
    position: "absolute",
    top: `${positionY}px`,
    left: `${positionX + 3}px`,
    opacity: `${visible ? 1 : 0}`,
    transition: "250ms opacity ease-in ease-out",
  };

  function Menu() {
    return (
      <>
        {items.map((item, index, arr) => {
          if (!item.label) {
            throw new Error(
              "Each object in the array of items should compulsarily have Label property. Please check the items props"
            );
          }

          if (item?.render && !item.render(data)) return null;

          return (
            <div
              key={index}
              onClick={() => {
                setState({ ...state, visible: false });
                item.callback(data);
              }}
              className="contextMenu--option"
              index={index}
            >
              {!item.checkStatus
                ? item.label
                : data.status === "active"
                ? "Delete"
                : "Recover"}
            </div>
          );
        })}
      </>
    );
  }

  return (
    (visible || null) && (
      <div className="contextMenu" ref={contextRef} id="customMenu" style={parentStyle}>
        <Menu />
      </div>
    )
  );
}

export { useContextMenu };
export { ContextMenuProvider, StoreContext };

export default ContextMenu;
