import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";
import styles from "./styles";
import { Box, Typography } from "@mui/material";
import PathHeader from "components/UI/PathHeader";

function About() {
  return (
    <>
      <Header />

      <Box sx={styles.mainBox}>
        <Box sx={styles.subBox}>
          <PathHeader header="परिचय" subPath="होम / परिचय" />

          <Box
            sx={{
              p: 4,
              boxShadow: `0 0 7px 1px #f5deb1 !important`,
              background: "white",
            }}
          >
            <Typography sx={{ textAlign: "center", color: "#e74c3c" }}>
              <strong>Vaidik Anusandhan Guthi (VAG) </strong>
            </Typography>

            <div
              className="chanakya_font"
              style={{ textAlign: "justify", lineHeight: "2" }}
            >
              &nbsp;{" "}
              <span style={{ fontSize: "22px" }}>
                भगवान ने कहा है <strong>‘ममैवांशो जीवलोके</strong>
                <strong>’</strong> - यह जीव मेरा ही अंश है। प्राणीमात्र सच्चिदानन्द
                परमात्माके अंश हैं। सभी प्राणियोंमें केवल मनुष्य ही ऐसा है जो अपने विवेकका
                प्रयोग करके परमात्माको प्राप्त कर सकता है। सच्चिदानन्दका अंश होकर भी आज
                जगत् दुःखी हो रहा है, इसमें एकमात्र कारण अपने विवेकका दुरुपयोग है।
                वास्तवमें काम, क्रोध, लोभ, ईर्ष्या, अभिमान आदिके कारण ही मनुष्य दुःखी हो
                रहा है।&nbsp;
                <br />
                &nbsp; जैसे दो वर्षके बालकके माँ-बाप मर जायँ, वह माँ-बापके मरनेके तत्त्वको
                नहीं समझता, वह हँसता-खेलता रहता है। उसे भावीका पता नहीं कि उसके ऊपर कितना
                कष्ट आ गया और उसको भविष्यमें कितने कष्ट झेलने पड़ सकते हैं। इसी तरह
                संसारमें आसक्त मनुष्योंकी दशा है। उन्हें पता ही नहीं कि यदि इस जन्ममें
                भगवत्प्राप्ति नहीं की तो हमें कितनी बार जन्मना-मरना पड़ेगा। समुद्रकी
                लहरोंकी गणना भले ही सम्भव हो; परन्तु मनुष्यको कितनी बार जन्मना-मरना
                पड़ेगा, गिनना सम्भव ही नहीं है। किन-किन योनियोंमें जाकर कितना महान् कष्ट
                भोगना पड़ सकता है, इसकी सुध ही नहीं है।&nbsp;
                <br />
                &nbsp; मनुष्ययोनि पाकर भी प्रायः मनुष्य भोग भोगने और धन संग्रह करनेमें ही
                लगे हुए हैं। मनुष्य-जीवनका उद्देश्य भगवत्प्राप्ति ही है। इस बातको जाननेकी
                बात तो दूर रही, लोग ठीक तरहसे सुनना भी नहीं चाहते। प्रायः मनुष्य स्वर्गको
                ऊँचा मानते हैं तथा उसे चाहते हैं; जबकि देवयोनि भी भोगयोनि ही है, वहाँ से
                पुनः पतन निश्चित है। ऐसा जीवन पशु-जीवनके तुल्य नहीं, बल्कि उससे भी नीचा
                है। &nbsp;पशु तो प्रारब्ध कर्म भोगकर उत्थानकी ओर जा रहा है और मनुष्य
                नये-नये पाप करके आसुरीयोनि और नरकोंकी तैयारी कर रहा है। इसी दशापर भगवान्
                को तरस आता है कि मैंने इसे मनुष्ययोनि मेरे पास आनेके लिये दी थी, परन्तु यह
                किधर जा रहा है। इसी बातको आसुरी सम्पदाके प्रकरणमें गीता १६। २० में ‘
                <strong>मामप्राप्यैव कौन्तेय ततो यान्त्यधमां गतिम्</strong>’ पदसे भगवान्
                ने कहा है। त्रिकालज्ञ ऋषियोंने, महात्माओंने परमात्माकी आज्ञानुसार शुभ कर्म
                करते हुए अन्तमें परमात्माकी प्राप्ति कर लेनेको ही परम कल्याण माना है।
                <br />
                &nbsp; महापुरुषोंको इस बातका प्रत्यक्ष अनुभव है कि मनुष्यका कल्याण न
                होनेके कारण उसको नारकीय यातनाएँ एवं अनेक तरहके जन्म-मरणके कष्ट भोगने पड़ते
                हैं, इसलिये उनका एक ही उद्देश्य रहता है कि यह जीव जिसको मनुष्य-जन्म मिल
                गया वह अपना उद्धार कर ले, जन्म-मर‍णसे पिण्ड छुड़ा ले, भगवत्प्राप्तिसे
                वंचित न रहे। इसलिये महापुरुषोंका विशेष प्रयत्न इस दिशामें होता है।&nbsp;
                <br />
                &nbsp; गीताप्रेसके संस्थापक परम श्रद्धेय जयदयालजी गोयन्दका ‘सेठजी’ आजीवन
                इसी लक्ष्यको लेकर प्रयत्नशील रहे। सिद्धियों तथा सांसारिक उपलब्धियोंको वे
                महत्व नहीं देते थे‍। एक दिन उन्होंने अपने प्रवचनमें कहा कि जिसे
                भगवत्प्राप्तिकी कामना हो उसे नित्य भगवान् से प्रार्थना करनी चाहिये कि हे
                प्रभो! मुझे धन मत देना। सेठजीके पूर्व गीता केवल संन्यासियोंके लिये या
                मरनेवालोंको सुनानेके लिये मानी जाती थी। लोग इस भ्रमसे इतना भ्रमित थे कि वे
                अपने बाल-बच्चोंको गीता पढ़ाना नहीं चाहते थे। आज वही गीता सर्वत्र शुद्ध और
                सस्ते मूल्यमें मिलनेके कारण जन-जनका कल्याण कर रही है। लगभग पाँच हजार वर्ष
                पूर्व गीताका उपदेश भगवान् श्रीकृष्णने दिया, इसका प्रसार सेठजी द्वारा अब
                हुआ है। इसी प्रकार गोस्वामी तुलसीदासजीके रामचरितमानसका प्रचार भी लगभग ४००
                &nbsp;वर्षों बाद अब हुआ है।&nbsp;
                <br />
                &nbsp; भगवत्प्राप्ति केवल सन्त-महात्मा, ऋषि-मुनि आदि ही कर सकते हैं, इस
                गलतफहमीको दूर कर जयदयालजी गोयन्दकाने मानवमात्रको भगवत्प्राप्तिका सहज सुगम
                मार्ग प्रदर्शित किया। वे कहा करते थे कि भगवत्प्राप्तिके लिये विशेष स्थान,
                समय आदिकी आवश्यकता नहीं है। जहाँ भी हो, जैसे भी हो, ‍जो भी हो, उसी
                अवस्थामें अविलम्ब कर सकते हो।
                <br />
                &nbsp; गीता सेवा ट्रस्टका उद्देश्य उपरोक्त उद्देश्योंकी प्राप्तिके लिये
                गीताप्रेसकी पुस्तकोंका प्रचार, वितरण, सन्त-महात्माओंके प्रवचनोंको उपलब्ध
                कराना, आधुनिक मीडिया यथा मोबाइल ऐप, एनीमेटेड फिल्म, चित्रकथा एवं अन्य सभी
                सम्भव साधनोंके द्वारा पूरे विश्वमें उच्च मानव-मूल्योंको स्थापित करना है।
              </span>
            </div>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default About;
