import React from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import { Popover, Box, MenuItem, Stack, Typography } from "@mui/material";
import theme from "theme";

const SortDropDown = ({ value, options, handleChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "sort-option-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (v) => {
    handleChange(v);
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        py={1}
        px={2}
        sx={{
          borderRadius: "4px",
          backgroundColor: theme.dimYellow,
          cursor: "pointer",
        }}
        aria-describedby={id}
        onClick={handleClick}
        gap={1}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {value}
        </Typography>
        <KeyboardArrowDown fontSize="small" />
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box mt={1}>
          {options.map((o) => (
            <MenuItem
              key={o.value}
              onClick={() => handleSelect(o)}
              // sx={{
              //   color: "#ea7a05",
              //   backgroundColor: "#ffb968",
              //   p: 0.5,
              //   minHeight: 0,
              //   fontSize: "14px",
              // }}
            >
              {o.title}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default SortDropDown;
