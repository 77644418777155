import React from "react";

import { Box, Typography, Collapse, Stack, MenuItem } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const CollapsibleDiv = ({
  title,
  options = [],
  description = "",
  isTitleColored = false,
  defaultExpanded = false,
  handleClickFilter,
  value,
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Box
      sx={{
        boxShadow: 2,
        // borderRadius: "10px",
        backgroundColor: "white",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        onClick={handleExpandClick}
        sx={{ cursor: "pointer", p: 2 }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>

        {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Stack>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack>
          {description ? (
            <>
              <Typography px={2} pb={2}>
                {description}
              </Typography>
            </>
          ) : isTitleColored ? (
            <Box pb={2}>
              {options.map((o) => (
                <Stack gap={0.5} px={2} direction="row" key={o.description}>
                  <Typography sx={{ fontWeight: "bold", color: "primary.main" }}>
                    {o.title}&nbsp;:-
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    &nbsp;{o.description}
                  </Typography>
                </Stack>
              ))}
            </Box>
          ) : (
            <>
              {options.map((o) => (
                <MenuItem
                  key={o.value}
                  onClick={() => handleClickFilter("category", o.value)}
                  sx={{ background: value === o.value ? "#FEBC5F50" : "white" }}
                >
                  {o.title}
                </MenuItem>
              ))}
            </>
          )}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default CollapsibleDiv;
