import theme from "theme";

const styles = {
  mainBox: {
    minHeight: "calc(100svh - 150px)",
    backgroundColor: theme.dimmerYellow,
    pb: 8,
  },
  subBox: { maxWidth: theme.maxWidth, margin: "0 auto", px: 2 },
};

export default styles;
